import { CalendarIcon, Menu as MenuIcon } from "lucide-react";

import { Link, useFetcher, useLocation, useNavigate, useParams } from "@remix-run/react";
import type { Session, SupabaseClient } from "@supabase/supabase-js";
import {
  dateToString,
  dateToWeekTitle,
  getMondayOfDate,
  getNextMonday,
  getPreviousMonday,
  stringToDate,
} from "~/utils/dates";
import { LogoWithText } from "./Logo";
import type { ButtonProps } from "./ui/button";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "./ui/sheet";
import { useEffect, useState } from "react";

const MenuButton = ({ buttonProps, children }: { children: React.ReactNode; buttonProps?: ButtonProps }) => {
  return (
    <SheetClose asChild>
      <Button variant="ghost" className="justify-start" asChild {...buttonProps}>
        {children}
      </Button>
    </SheetClose>
  );
};

const SideMenu = ({ session, supabase }: { session?: Session; supabase?: SupabaseClient }) => {
  const fetcher = useFetcher();
  const thisWeek = dateToString(getMondayOfDate());
  const nextWeek = dateToString(getNextMonday());
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon">
          <MenuIcon className="h-6 w-6 text-purple-900" />
          <span className="sr-only">Open menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left">
        <nav className="flex flex-col gap-4 mt-4">
          {!session?.user ? (
            <MenuButton>
              <Link to="/login">Sign in/up</Link>
            </MenuButton>
          ) : null}
          <MenuButton>
            <Link to={`/plan/${thisWeek}`}>This week's menu</Link>
          </MenuButton>
          <MenuButton>
            <Link to={`/plan/explore/${nextWeek}`}>Plan next week</Link>
          </MenuButton>
          <MenuButton>
            <Link to="/favorites">Favorites</Link>
          </MenuButton>
          <MenuButton>
            <Link to="/save_for_later">Saved for later</Link>
          </MenuButton>
          <MenuButton>
            <Link to="/about">About</Link>
          </MenuButton>
          {session?.user && supabase ? (
            <MenuButton
              buttonProps={{
                onClick: async () => {
                  await supabase?.auth.signOut();
                  fetcher.submit({}, { method: "post", action: "/signout" });
                },
              }}
            >
              Sign out
            </MenuButton>
          ) : null}
        </nav>
      </SheetContent>
    </Sheet>
  );
};

const DateSelector = () => {
  const navigate = useNavigate();
  const params = useParams();
  const currentDate = params.date ? stringToDate(params.date) : undefined;
  const dateStr = currentDate ? dateToWeekTitle(currentDate) : undefined;
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname.split("/");
  const basePath = path[2] === "explore" ? `/plan/explore` : "/plan";

  useEffect(() => {
    setIsOpen(false);
  }, [params.date]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button variant={"outline"} className="w-40 lg:w-52 pl-3 text-left font-normal bg-base-100">
          {dateStr ?? "Pick a week"}
          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 bg-base-100" align="end">
        <Calendar
          mode="single"
          selected={currentDate}
          onSelect={(newDate) => {
            if (newDate) {
              navigate(`${basePath}/${dateToString(newDate)}`);
            }
          }}
          initialFocus
          disabled={{ dayOfWeek: [0, 2, 3, 4, 5, 6] }}
          today={getPreviousMonday()}
        />
      </PopoverContent>
    </Popover>
  );
};

export const NavBar = ({ session, supabase }: { session?: Session; supabase?: SupabaseClient }) => {
  return (
    <div className="navbar">
      <div className="navbar-start">
        <SideMenu session={session} supabase={supabase} />
        <Link to="/" className="h-8 lg:h-10 px-2">
          <LogoWithText height="100%" />
        </Link>
      </div>
      <div className="navbar-end">
        <DateSelector />
      </div>
    </div>
  );
};
